import React from 'react';

import { Container, Row, Col } from '../../components/Bootstrap/Grid';
import Button from '../../components/Bootstrap/Button';
import Layout from './parts/Layout';
import Comma from './parts/Comma';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const Login = () => (   
    <Layout layout="login">
        <Container pt={{xs: 6, lg: 10}} pb={{xs: 9, lg: 11}}>
            <Row {...className('h-100')}>
                <Col col={{xs: 12, lg: 10}}>                
                    <div {...className(`${styles.login} px-2 px-sm-3 px-md-7 px-xl-8 px-xxl-10 py-6 py-lg-10 h-100 w-100 align-self-center`)}>
                        <div>
                            <h2 {...className('d3 text-primary mb-6')}>
                                404,
                            </h2>
                            <p {...className('mb-7')}>
                                No hemos encontrado lo que buscas.
                            </p>
                            <Button 
                                {...className(`${styles.button} button-2-b mt-lg-2 mt-xl-4`)} 
                                tag="a" 
                                text="Ir a la home"
                                href="/"
                                target="_self"
                            />
                        </div>
                        <Comma className={styles.comma} />
                    </div>
                </Col>
            </Row>
        </Container>
    </Layout>
)

export default Login;
